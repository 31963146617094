import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import slide1 from 'assets/images/slide-1.png';
import slide1Webp from 'assets/images/slide-1.png?as=webp';
import slide2 from 'assets/images/slide-2.png';
import slide2Webp from 'assets/images/slide-2.png?as=webp';
import slide3 from 'assets/images/slide-3.png';
import slide3Webp from 'assets/images/slide-3.png?as=webp';
import slide4 from 'assets/images/slide-4.png';
import slide4Webp from 'assets/images/slide-4.png?as=webp';
import priceDel from 'assets/images/price-del.png';
import LazyLoad from 'react-lazyload';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import {
  CURRENCY_SIGN, EMOJI, PRODUCTS_ID_MAPPING, SUPPORTED_REGIONS,
} from '../../../shared/enums';
import { getTrue, getPerfectSquare } from '../../../shared/utils';
import { selectLocalizationData } from '../../features/localization/localizationSlice';
import {
  selectRegionalPricesData, selectRegionalPricesError,
} from '../../features/regionalPrices/regionalPricesSlice';

const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param imageSettings
 * @param setImageSlider
 * @returns {JSX.Element}
 * @constructor
 */
const AboutBlockImageCarousel = ({ imageSettings, setImageSlider }) => (
  <Slider className='about-block__image-carousel' {...imageSettings} ref={slider => setImageSlider(slider)}>
    <figure className='about-block__image-slide about-block__image-slide1'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={slide1Webp} type='image/webp' />
            <source srcSet={slide1} type='image/png' />
            <img
              className='about-block__image'
              src={slide1}
              alt='slide1'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='about-block__image-slide about-block__image-slide2'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={slide2Webp} type='image/webp' />
            <source srcSet={slide2} type='image/png' />
            <img
              className='about-block__image'
              src={slide2}
              alt='slide2'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='about-block__image-slide about-block__image-slide3'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={slide3Webp} type='image/webp' />
            <source srcSet={slide3} type='image/png' />
            <img
              className='about-block__image'
              src={slide3}
              alt='slide3'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
    <figure className='about-block__image-slide about-block__image-slide4'>
      <LazyLoad>
        <FadeIn>
          <picture>
            <source srcSet={slide4Webp} type='image/webp' />
            <source srcSet={slide4} type='image/png' />
            <img
              className='about-block__image'
              src={slide4}
              alt='slide4'
            />
          </picture>
        </FadeIn>
      </LazyLoad>
    </figure>
  </Slider>
);

/**
 * @param contentSettings
 * @param setImageSlider
 * @param localization
 * @param measure
 * @param value
 * @param sign
 * @param unit
 * @param onClick
 * @returns {JSX.Element}
 * @constructor
 */

const AboutBlockCarousel = ({
  contentSettings, setContentSlider, localization, measure, value, sign, unit, setHeight, onClick,
}) => (
  <div className='about-block__carousel-wrap' style={{ height: `${setHeight}px` }} onClick={() => onClick()}>
    <Slider
      className='about-block__carousel'
      {...contentSettings}
      ref={slider => setContentSlider(slider)}
    >
      <div className='about-block__slide'>
        <span className='about-block__slide-number'>01</span>
        <h3 className='about-block__slide-title'>Decide how many Milas you need.</h3>
        <p className='about-block__slide-description'>
          Each Mila unit cleans a room up to
          {' '}
          {getPerfectSquare(1000, localization.region, measure)}
          {' '}
          {measure}
          <sup>2</sup>
          — and
          they’re refreshingly affordable at just
          {' '}
          {(getTrue(value) && true) && (
            <span style={{
              color: '#979797',
              display: 'inline-block',
              marginRight: '10px',
              verticalAlign: 'middle',
              position: 'relative',
            }}
            >
              <img
                src={priceDel}
                alt='old-price'
                style={{
                  position: 'absolute',
                  top: '14px',
                  transform: 'rotate(3.42deg)',
                }}
              />

              {sign}
              {unit.price}
            </span>
          )}
          {sign}
          {(getTrue(value) && true) ? unit.discount_price : unit.price}
          {' '}
          each.
        </p>
      </div>
      <div className='about-block__slide'>
        <span className='about-block__slide-number'>02</span>
        <h3 className='about-block__slide-title'>Customize your filters.</h3>
        <p className='about-block__slide-description'>
          Choose a personalized filter for each unit, depending on
          specific air issues you need to solve.
          Don't have any? Our Basic Breather filter is perfect for everyday
          cleaning.
        </p>
      </div>
      <div className='about-block__slide'>
        <span className='about-block__slide-number'>03</span>
        <h3 className='about-block__slide-title'>Get fresh filters every 6 months.</h3>
        <p className='about-block__slide-description'>
          Subscribe to Auto-Refill and save
          {' '}
          {sign}
          {sign}
          {' '}
          (and time). Your first filter is free and you can cancel at
          any time. (Or buy them the old fashioned way … no pressure!)
        </p>
      </div>
      <div className='about-block__slide'>
        <span className='about-block__slide-number'>04</span>
        <h3 className='about-block__slide-title'>Welcome Mila and breathe easy.</h3>
        <p className='about-block__slide-description'>
          Simple setup means you’ll have Mila working her magic in
          minutes.
          {' '}
          {EMOJI.SPARKLES}
        </p>
      </div>
    </Slider>
  </div>
);

/**
 * @returns {null|JSX.Element}
 * @constructor
 */
const AboutBlock = () => {
  const localization = useSelector(selectLocalizationData);
  const regionalPrices = useSelector(selectRegionalPricesData);
  const error = useSelector(selectRegionalPricesError);

  const unit = regionalPrices.find(price => price.variant === PRODUCTS_ID_MAPPING.MILA_UNIT);
  const sign = CURRENCY_SIGN[localization.region] || CURRENCY_SIGN.US;
  const measure = [ SUPPORTED_REGIONS.US, SUPPORTED_REGIONS.CA ].includes(localization.region) ? 'ft' : 'm';

  const [ contentSlider, setContentSlider ] = useState(null);
  const [ imageSlider, setImageSlider ] = useState(null);
  const [ sliderInit, setSliderInit ] = useState(false);
  const [ slideIndex, setSlideIndex ] = useState(0);
  const [ isMobile, setIsMobile ] = useState(false);

  const [ contentSliderHeight, setContentSliderHeight ] = useState(0);

  const contentSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    dots: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    autoplaySpeed: 7000,
    vertical: true,
    autoplay: true,
    speed: 1000,
    verticalSwiping: true,
    asNavFor: imageSlider,

    onInit: () => {
      setSliderInit(true);
    },
    afterChange: index => {
      setSlideIndex(index);
    },
    responsive: [
      {
        breakpoint: 769,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
        },
      },
    ],
  };

  const imageSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    arrows: false,
    fade: true,
    autoplay: false,
    pauseOnHover: false,
    pauseOnFocus: false,
    speed: 500,
    asNavFor: contentSlider,
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 769) {
        setIsMobile(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!sliderInit) return;
    setContentSliderHeight(isMobile ? 541 : 591);
  }, [ isMobile, slideIndex, sliderInit ]);

  const onClick = () => {
    contentSlider.slickNext();
  };

  return error ? null : (
    <section className='about-block'>
      <div className='about-block__inner'>
        <div className='about-block__intro'>
          <h2 className='about-block__title'>How it works.</h2>
          <div className='about-block__description'>
            <p>Mila makes purifying your air simpler and more affordable than ever.</p>
          </div>
        </div>
        <AboutBlockImageCarousel
          imageSettings={imageSettings}
          setImageSlider={setImageSlider}
        />

        <AboutBlockCarousel
          setHeight={contentSliderHeight}
          contentSettings={contentSettings}
          setContentSlider={setContentSlider}
          localization={localization}
          measure={measure}
          value={ENABLE_DISCOUNT}
          sign={sign}
          unit={unit}
          onClick={onClick}
        />
      </div>
    </section>
  );
};

export default AboutBlock;
